import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/Dashboard/DashboardApp';
import NotFound from './pages/Page404';
import { LOGIN_KEY } from './constants';

// ----------------------------------------------------------------------
function RequireAuth({ children, redirectTo, isLoggedIn }) {
  return isLoggedIn === true ? children : <Navigate to={redirectTo} />;
}
function getLogin(key) {
  let session = sessionStorage.getItem(key);
  if (session) {
    session = JSON.parse(session);
    if (session.loggedIn) {
      return true;
    }
  }
  return false;
}
export default function Router() {
  const login = getLogin(LOGIN_KEY);
  return useRoutes([
    {
      path: '/cloud',
      element: (
        <RequireAuth redirectTo="/login" isLoggedIn={login}>
          <DashboardLayout />
        </RequireAuth>
      ),
      children: [
        {
          element: (
            <RequireAuth redirectTo="/login" isLoggedIn={login}>
              <Navigate to="/cloud/home" replace />
            </RequireAuth>
          )
        },
        {
          path: 'home',
          element: <DashboardApp />
        }
        // { path: 'user', element: <User /> },
        // { path: 'products', element: <Products /> },
        // { path: 'blog', element: <Blog /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        {
          path: '/',
          element: <Navigate to="/cloud" />
        },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
