import axios from 'axios';
import { API_KEY, LOCAL_ENDPOINT, LOCAL_PORT, SERVER_ENDPOINT } from '../../constants';

export async function submitPipeline(files, filters, drugsCheckbox, pathologiesCheckbox) {
  const formdata = new FormData();
  // formdata.append('files', JSON.stringify(files));
  const contextData = [];
  // eslint-disable-next-line array-callback-return
  files.map((file, index) => {
    contextData.push({
      filename: file.name,
      context: file.context,
      type: file.type,
      size: file.size
    });
    formdata.append(`files:${index}`, file.file);
  });
  formdata.append('filesContext', JSON.stringify(contextData));
  formdata.append('totalFiles', files.length);
  console.log('filters');
  console.log(filters);
  formdata.append('filters', filters);
  formdata.append('pathologiesCheckbox', pathologiesCheckbox ? 1 : 0);
  formdata.append('drugsCheckbox', drugsCheckbox ? 1 : 0);
  formdata.append('key', 'KJNSBVSOYUIB27ADCSV872DASCDS2');

  console.log('formdata');
  console.log(formdata);
  const response = await axios.post(`${SERVER_ENDPOINT}/submitpipe`, formdata, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*'
    }
  });
  const resBlob = response.data; // <--- store the blob if it is
  let resData = null;

  try {
    const resText = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('abort', reject);
      reader.addEventListener('error', reject);
      reader.addEventListener('loadend', () => {
        resolve(reader.result);
      });
      reader.readAsText(resBlob);
    });
    resData = JSON.parse(resText); // <--- try to parse as json evantually
  } catch (err) {
    // ignore
  }

  // Now you have `resData` and `resBlob` at the same time.
  // `resData` would be the normal data object,
  // or the error object if `resBlob` is expected.

  if (resData) {
    if (resData.error) {
      // handle error
      return resData;
    }
  }
  return response.data;
}

export async function sendRequest(data, url) {
  // data: dict { name: 'Jaime2', surname: 'Caso2' }
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  };
  // eslint-disable-next-line no-return-await
  return await axios.get('http://127.0.0.1:5000');
}
