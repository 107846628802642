import extension from 'extensionizer';

const extensionStorage = extension.storage && extension.storage.local;

class ExtensionHelper {
  // eslint-disable-next-line no-empty-function,no-useless-constructor
  constructor() {}

  /**
   * Set element by key
   * @param {string} key
   * @param {*} value
   */
  saveLocal = (key, value) => localStorage.setItem(key, value);

  /**
   * Get element by key
   * @param {*} key
   */
  getLocal = (key) => localStorage.getItem(key);

  /**
   * Remove by key
   * @param {string} key
   */
  removeLocal = (key) => {
    localStorage.removeItem(key);
  };

  /**
   * Remove all storage
   */
  clearLocal = () => {
    localStorage.clear();
  };
}
const extensionHelper = new ExtensionHelper();
export default extensionHelper;
