export const API_KEY = 'KJNSBVSOYUIB27ADCSV872DASCDS2';

export const LOGIN_KEY = 'LOGIN_KEY';
export const FILES_KEY = 'FILES_KEY';

export const LOGIN_USER = {
  user: 'test@myomics.io',
  password: 'myomicscloud2020'
};

export const LOCAL_PORT = '5001';
export const LOCAL_ENDPOINT = `http://127.0.0.1:${LOCAL_PORT}`;
export const SERVER_ENDPOINT = 'https://api.myomics.io';

export const FILE_CONTEXT_TYPE_ANALYZE = 'ANALYZE';
export const FILE_CONTEXT_TYPE_COMPARE = 'COMPARE';
export const FILE_CONTEXT_TYPE_COMPARE_SAME = 'SAME';
export const FILE_CONTEXT_TYPE_COMPARE_DIFFERENCE = 'DIFFERENCE';

// DataBases
export const HP_LABEL = 'HP Id';
export const HP_KEY = 'HP';
export const DB_OPTIONS = [
  {
    id: 0,
    placeholder: 'KEY:00***',
    label: 'Human Phenotype Ontology',
    key: 'HPO',
    value: '',
    selected: false,
    regex: /(^HP:|^OMIM:|^ORPHA:).{1,}/
  },
  {
    id: 1,
    placeholder: '[0,1]',
    placeholder2: 'Enter target',
    label: 'Open Targets Platform',
    key: 'OTP',
    value: '',
    value2: '',
    selected: false,
    regex: /^(0(\.\d+)?|1(\.0+)?)$/
  },
  {
    id: 2,
    placeholder: 'column',
    placeholder2: 'key',
    label: 'Dataframes filter',
    key: 'DATAF',
    value: '',
    value2: '',
    selected: false,
    regex: null
  }
];
