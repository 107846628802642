import faker from 'faker';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { formatDistance } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import plusIcon from '@iconify/icons-eva/plus-square-fill';
// material
import {
  Box,
  Stack,
  Link,
  Card,
  Button,
  Divider,
  Typography,
  CardHeader,
  MenuItem,
  TextField
} from '@mui/material';
// utils
import { mockImgCover } from '../../../utils/mockImages';
//
import Scrollbar from '../../Scrollbar';
import { BlogPostsSort } from '../blog';
import POSTS from '../../../_mocks_/blog';
import SearchInput from '../../SearchInput/SearchInput';
import SelectInput from '../../SelectInput/SelectInput';

// ----------------------------------------------------------------------

const NEWS = [...Array(5)].map((_, index) => {
  const setIndex = index + 1;
  return {
    title: faker.name.title(),
    description: faker.lorem.paragraphs(),
    image: mockImgCover(setIndex),
    postedAt: faker.date.soon()
  };
});

// ----------------------------------------------------------------------

FilterItem.propTypes = {
  news: PropTypes.object.isRequired
};

function FilterItem({ news }) {
  const { image, title, description, postedAt } = news;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <input type="checkbox" checked={false} />
      <SearchInput posts={POSTS} placehoder="Search field.." />
      <SelectInput value="Latest" options={[{ value: 'latest', label: 'Latest' }]} />
      {/* }
      <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
        {formatDistance(postedAt, new Date())}
      </Typography>
      */}
    </Stack>
  );
}

export default function Filters() {
  return (
    <Card>
      <CardHeader title="Filters" />
      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {NEWS.map((news) => (
            <FilterItem key={news.title} news={news} />
          ))}
          <Box sx={{ p: 2, textAlign: 'right' }}>
            <Button
              to="#"
              size="small"
              color="inherit"
              component={RouterLink}
              endIcon={<Icon icon={plusIcon} />}
            >
              Add filter
            </Button>
          </Box>
        </Stack>
      </Scrollbar>
    </Card>
  );
}
