import React, { Component } from 'react';
import './Loader.scss';

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="loading-content">
        <div className="loading-ico" />
      </div>
    );
  }
}

export default Loader;
