import React, { Component } from 'react';
import './ToastContainer.scss';
import ToastItem from './ToastItem';

const defaultDuration = 2000;
class ToastContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowMask: false,
      toastList: [],
      currentToast: {}
    };
  }

  pushToast = (toastProps) => {
    const { type, text, duration, isShowMask = false } = toastProps;
    const lastDuration = duration || defaultDuration;
    this.setState({
      currentToast: {
        id: getUuid(),
        type,
        text,
        duration: lastDuration,
        isShowMask
      }
    });
  };

  popToast = () => {
    this.setState(
      {
        currentToast: {},
        isShowMask: false
      },
      () => {}
    );
  };

  render() {
    const { toastList, isShowMask, currentToast } = this.state;
    return (
      <div className="toast-container">
        {isShowMask && <div className="mask" />}
        <div className="toast-wrap">
          {currentToast.text && <ToastItem onClose={this.popToast} {...currentToast} />}
        </div>
      </div>
    );
  }
}

let toastCount = 0;

// eslint-disable-next-line no-plusplus
const getUuid = () => `toast-container${new Date().getTime()}-${toastCount++}`;

export default ToastContainer;
