// ----------------------------------------------------------------------

const account = {
  displayName: 'Test User',
  email: 'test@myomics.io',
  role: 'team',
  photoURL: '/static/mock-images/avatars/avatar_default.jpg'
};

export default account;
