// material
import { Grid, Container, Typography, Stack, Button, Input, Checkbox } from '@mui/material';
// components
import { useEffect, useState } from 'react';
import cx from 'classnames';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import cloudUploadIcon from '@iconify/icons-eva/cloud-upload-outline';
import plusIcon from '@iconify/icons-eva/plus-square-outline';
import downloadIcon from '@iconify/icons-eva/download-outline';
import Page from '../../components/Page';
import Toast from '../../components/Toast';
import filesUpload from '../../assets/img/files-upload.png';
import './index.scss';
// eslint-disable-next-line import/order
import closeCircleIcon from '@iconify/icons-eva/close-circle-outline';
import { getIcon } from '../../layouts/dashboard/SidebarConfig';
import extensionHelper from '../../lib/extensionHelper';
import {
  DB_OPTIONS,
  FILE_CONTEXT_TYPE_ANALYZE,
  FILE_CONTEXT_TYPE_COMPARE,
  FILE_CONTEXT_TYPE_COMPARE_DIFFERENCE,
  FILE_CONTEXT_TYPE_COMPARE_SAME,
  FILES_KEY,
  HP_KEY,
  HP_LABEL
} from '../../constants';
import { md5 } from '../../lib/cryptoLib';
import { addressSlice, bytesToSize, convertMiliseconds, testRegex } from '../../utils/utils';
import DataTable from '../../components/DataTable/DataTable';
import FileStats from '../../components/_dashboard/app/FileStats';
import { BlogPostsSearch, BlogPostsSort } from '../../components/_dashboard/blog';
import { AppOrderTimeline } from '../../components/_dashboard/app';
import POSTS from '../../_mocks_/blog';
import Filters from '../../components/_dashboard/app/Filters';
import SelectInput from '../../components/SelectInput/SelectInput';
import SearchInput from '../../components/SearchInput/SearchInput';
// eslint-disable-next-line import/named
import { sendRequest, submitPipeline } from '../../lib/endpoint';
import { colors } from '../../constants/colors';
import Loader from '../../components/Loader';

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' }
];
const moment = extendMoment(Moment);

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [files, setFiles] = useState([]);
  const [zipsFromServer, setZipsFromServer] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dbKey, setDbKey] = useState('');
  const [dbKeyList, setDbKeyList] = useState(DB_OPTIONS);
  const [pathologiesCheckbox, setPathologiesCheckbox] = useState(0);
  const [drugsCheckbox, setDrugsCheckbox] = useState(0);
  const [loadFileStatus, setLoadFileStatus] = useState(false);
  const [loadZipStatus, setLoadZipStatus] = useState(false);
  const [includeHpoId, setIsHpoId] = useState('yes');
  const [requestPerformance, setRequestPerformance] = useState('');

  useEffect(() => {
    // Your code here
    // const filesInLocalString = extensionHelper.getLocal(FILES_KEY) || '[]';
    // const filesInLocal = JSON.parse(filesInLocalString);
    // if (filesInLocal.length && JSON.stringify(filesInLocal) !== JSON.stringify(files)) {
    //  setFiles(filesInLocal);
    // }
  }, []);

  function saveFile(data, id) {
    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: 'application/zip'
      })
    );
    const link = document.createElement('a');
    link.href = url;
    let fileName = `files-result(${id}).zip`;
    if (id === 1) {
      fileName = `files-result.zip`;
    }
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    // saveAs(url, filename);
  }
  function uploadMultipleFiles(event) {
    event.preventDefault();
    const { id } = event.target;
    const { files } = event.target;
    const fileArray = Array.from(files);
    const totalFiles = fileArray.length;
    // eslint-disable-next-line array-callback-return
    fileArray.map((file) => {
      onFileUpload(file, id);
    });
  }
  function onFileUpload(file, id) {
    setLoadFileStatus(true);
    // Create an instance of FileReader API
    const fileReader = new FileReader();
    fileReader.onload = () => {
      // After uploading the file
      // appending the file to our state array
      // set the object keys and values accordingly
      // const filesInLocalString = extensionHelper.getLocal(FILES_KEY) || '[]';
      // const filesInLocalStorage = JSON.parse(filesInLocalString);

      const alreadyInLocal = files.filter((fileInstate) => file.name === fileInstate.name);
      if (!alreadyInLocal.length) {
        const newFile = {
          id: (files.length && files[files.length - 1].id + 1) || 0,
          hash: md5(fileReader.result),
          name: file.name,
          file,
          // base64: fileReader.result,
          type: file.type,
          size: file.size,
          selected: false,
          context: { type: FILE_CONTEXT_TYPE_ANALYZE, compare: '' },
          lastEdit: moment.utc().format()
        };
        setFiles([...files, newFile]);
      }
      setLoadFileStatus(false);
    };
    // reading the actual uploaded file
    fileReader.readAsDataURL(file);
  }
  const setSelectedFile = (fileName) => {
    let selecFile;
    const updatedFiles = files.map((file) => {
      if (fileName === file.name) {
        file.selected = !file.selected;
        selecFile = file;
      }
      return file;
    });
    setSelectedFiles([...selectedFiles, selecFile]);
    setFiles(updatedFiles);
  };
  const setFileContextCompare = (fileName, context, compare) => {
    let cpm = '';
    if (compare.length) {
      cpm = compare;
    }
    const newContext = context;
    newContext.compare = compare;

    const updatedFiles = files.map((file) => {
      if (fileName === file.name) {
        file.context = newContext;
      }
      return file;
    });
    setFiles(updatedFiles);
  };

  const setIsHpo = (isHpo) => {
    setIsHpoId(isHpo);
  };

  const setFileContext = (fileName, context, type) => {
    const newContext = context;
    newContext.type = type;
    const updatedFiles = files.map((file) => {
      if (fileName === file.name) {
        file.context = newContext;

        // init compare if type is FILE_CONTEXT_TYPE_COMPARE
        if (file.context.type === FILE_CONTEXT_TYPE_COMPARE) {
          file.context.compare = FILE_CONTEXT_TYPE_COMPARE_SAME;
        }
      }
      return file;
    });
    setFiles(updatedFiles);
  };
  const removeFile = (hash) => {
    const updatedFiles = files.filter((file) => file.hash !== hash);
    // extensionHelper.saveLocal(FILES_KEY, JSON.stringify(filesInLocal));
    setFiles(updatedFiles);
  };

  const removeZip = (id) => {
    const updatedZips = zipsFromServer.filter((file) => file.id !== id);
    // extensionHelper.saveLocal(FILES_KEY, JSON.stringify(filesInLocal));
    setZipsFromServer(updatedZips);
  };
  const submitProcess = async () => {
    setLoadZipStatus(true);
    const filesToSend = files.filter((file) => file.selected);

    let validValues = true;
    const selectedFilters = dbKeyList.filter((f) => f.selected);
    const bdFilters = selectedFilters.filter((f) => {
      let validateRegex = true;
      if (f.regex) {
        validateRegex = testRegex(f.regex, f.value);
        if (!validateRegex) {
          validValues = false;
          Toast.info(`${f.key}, value not valid: ${f.value}`, 4000);
        }
      }
      return f.value.length && f.selected && validateRegex;
    });
    const filtersToSend = bdFilters;
    const validFilters = selectedFilters.length === bdFilters.length;

    let containsA = false;
    // eslint-disable-next-line array-callback-return
    filesToSend.map((file) => {
      if (file.context.type === FILE_CONTEXT_TYPE_ANALYZE) {
        containsA = true;
      }
    });
    let containsB = false;
    // eslint-disable-next-line array-callback-return
    filesToSend.map((file) => {
      if (file.context.type === FILE_CONTEXT_TYPE_COMPARE) {
        containsB = true;
      }
    });
    let validContext = (containsB && containsA) || containsA;

    const validateFiles = filesToSend.length;

    const validateFilters = bdFilters.length;

    const validFiles = validateFiles && validContext;
    const validWithFilters = validFiles && validateFilters && validValues;
    // Context validation
    if (containsA && (containsB || bdFilters.length > 0)) {
      validContext = true;
    }
    if (!validateFiles) {
      Toast.info('No files selected', 3000);
      setLoadZipStatus(false);
      return;
    }
    if (!validContext) {
      Toast.info('Files context not valid', 3000);
      setLoadZipStatus(false);
      return;
    }

    if (!validFilters && bdFilters.length) {
      if (validFiles && !validValues) {
        Toast.info('No valid values in filters', 3000);
        setLoadZipStatus(false);
        return;
      }

      if (validFiles && !validateFilters) {
        Toast.info('No filters provided', 3000);
        setLoadZipStatus(false);
        return;
      }
    }

    if (validWithFilters || validFiles) {
      // const result = await sendRequest({ name: 'Jaime2', surname: 'Caso2' }, '');
      let dataResult;
      let perfomanceMessage;
      try {
        const startTime = performance.now();
        console.log('filtersToSend');
        console.log(filtersToSend);
        dataResult = await submitPipeline(
          filesToSend,
          JSON.stringify(filtersToSend),
          drugsCheckbox,
          pathologiesCheckbox
        );
        if (dataResult.error) {
          setLoadZipStatus(false);
          Toast.info(`Server failed: \n ${dataResult.error}`, 5000);
          return;
        }
        const endTime = performance.now();
        const milisec = endTime - startTime;
        const perf = convertMiliseconds(milisec);
        perfomanceMessage = '';
        if (perf.d) {
          perfomanceMessage += `${perf.d}d `;
        }
        if (perf.h) {
          perfomanceMessage += `${perf.h}h `;
        }
        if (perf.m) {
          perfomanceMessage += `${perf.m}m `;
        }
        if (perf.s) {
          perfomanceMessage += `${perf.s}s `;
        }
        // setRequestPerformance(perf);
      } catch (e) {
        Toast.info('Server failed', 3000);
        return;
      }

      if (dataResult && dataResult.size < 50) {
        Toast.info('No result from selected files', 5000);
        return;
      }
      if (dataResult && validContext) {
        Toast.info(`time ${perfomanceMessage}`, 5000);
        // at least the .zip has to be more that 50 bytes
        const totalZips = zipsFromServer.length;
        const includedFiles = files
          .filter((file) => file.selected)
          .map((file) => ({
            name: file.name,
            context: file.context
          }));
        setZipsFromServer([
          ...zipsFromServer,
          {
            id: totalZips + 1,
            data: dataResult,
            usedFiles: includedFiles,
            time: moment(moment.now()).format('HH:mm:ss DD/MM/YY'),
            bg: colors[Math.floor(Math.random() * colors.length)],
            timeExec: perfomanceMessage
          }
        ]);
      }
      setLoadZipStatus(false);
    } else {
      setLoadZipStatus(false);
    }
  };
  const handleChangeDbKey = (id, key) => {
    const dbKeys = dbKeyList;

    const r = dbKeys.map((dbK) => {
      if (dbK.id === id) {
        dbK.key = key;
      }
      return dbK;
    });
    setDbKeyList(r);
  };
  const handleChangeDbValue = (id, value) => {
    const dbKeys = dbKeyList;

    const r = dbKeys.map((dbK) => {
      if (dbK.id === id) {
        dbK.value = value;
      }
      return dbK;
    });
    setDbKeyList(r);
  };
  const handleChangeDbValue2 = (id, value) => {
    const dbKeys = dbKeyList;

    const r = dbKeys.map((dbK) => {
      if (dbK.id === id) {
        dbK.value2 = value;
      }
      return dbK;
    });
    setDbKeyList(r);
  };
  const handleCheckDbValue = (id) => {
    const dbKeys = dbKeyList;

    const r = dbKeys.map((dbK) => {
      if (dbK.id === id) {
        dbK.selected = !dbK.selected;
      }
      return dbK;
    });
    setDbKeyList(r);
  };
  const addFilter = async () => {
    const dbKeys = dbKeyList;
    const id = dbKeys[dbKeys.length - 1].id + 1;
    dbKeys.push({ id, placeholder: 'No value', key: '--', value: '--', selected: false });
    setDbKeyList((prev) => dbKeys);
    // setDbKeyList(dbKeys);
  };

  return (
    <Page title="Home | myOMCIS">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <input
            style={{ display: 'none' }}
            type="file"
            name="file"
            accept=".csv,.xlsx"
            id="uploadFiles"
            className="inputfile"
            onChange={(e) => uploadMultipleFiles(e)}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="uploadFiles">
            <Button
              disabled={loadFileStatus}
              variant="contained"
              to="#"
              startIcon={<Icon icon={plusFill} />}
            >
              Upload new file
            </Button>
          </label>
        </Stack>
        <Stack
          className={cx({
            // eslint-disable-next-line
            'uplodadedFilesStack': files.length,
          })}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <div className="scrollmenuContainer">
            <div className="scrollmenu">
              {files.map((file, index) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  className={cx({
                    // eslint-disable-next-line
                    'selectedItem': file.selected,
                    // eslint-disable-next-line
                    'fileItem': true
                  })}
                  key={index}
                >
                  <div className="fileItemRemoveContainer">
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <span
                      onClick={() => {
                        removeFile(file.hash);
                        Toast.info(`${file.name} removed`);
                      }}
                      className="fileItemRemove"
                    >
                      {getIcon(closeCircleIcon)}
                    </span>
                  </div>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  <div className="fileItemPropsContainer">
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <div className="pointer" onClick={() => setSelectedFile(file.name)}>
                      <p className="fileItemName">
                        <strong>{file.name.substring(0, 14)}</strong>
                      </p>
                      <hr />
                      <p className="fileItemName">{bytesToSize(file.size)}</p>
                    </div>
                    {/* }
                    <p className="fileItemName">
                      {moment(file.lastEdit).format('HH:mm:ss DD/MM/YY')}
                    </p>
                    */}
                    <div className="fileSelect">
                      <SelectInput
                        options={[
                          { value: FILE_CONTEXT_TYPE_ANALYZE, label: FILE_CONTEXT_TYPE_ANALYZE },
                          { value: FILE_CONTEXT_TYPE_COMPARE, label: FILE_CONTEXT_TYPE_COMPARE }
                        ]}
                        onSelect={(v) => setFileContext(file.name, file.context, v.target.value)}
                      />
                    </div>
                    {file.context.type === FILE_CONTEXT_TYPE_COMPARE ? (
                      <div className="fileSelect">
                        <SelectInput
                          options={[
                            {
                              value: FILE_CONTEXT_TYPE_COMPARE_SAME,
                              label: FILE_CONTEXT_TYPE_COMPARE_SAME
                            },
                            {
                              value: FILE_CONTEXT_TYPE_COMPARE_DIFFERENCE,
                              label: FILE_CONTEXT_TYPE_COMPARE_DIFFERENCE
                            }
                          ]}
                          onSelect={(v) =>
                            setFileContextCompare(file.name, file.context, v.target.value)
                          }
                          className="fileSelect"
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
              {loadFileStatus ? (
                <div
                  className={cx({
                    // eslint-disable-next-line
                    'selectedItem': false,
                    // eslint-disable-next-line
                    'loading': true
                  })}
                >
                  <Loader />
                </div>
              ) : null}
            </div>
          </div>
        </Stack>
        {files.filter((file) => file.selected).length > 0 ? (
          <div className="padding5px selectedFiles">
            <p>Selected files:</p>
            {files
              .filter((file) => file.selected)
              // eslint-disable-next-line array-callback-return
              .map((file) => (
                <p className="hoverBold">
                  {file.name}
                  <span> ➝ {file.context.type}</span>
                  {file.context.type === FILE_CONTEXT_TYPE_COMPARE ? (
                    <span> ➝{file.context.compare}</span>
                  ) : null}
                </p>
              ))}
          </div>
        ) : (
          <p className="padding5px selectedFiles">No selected files..</p>
        )}
        <div className="padding5px docxCheckbox">
          <Checkbox value={drugsCheckbox} onClick={() => setDrugsCheckbox(!drugsCheckbox)} />
          <span>Generate drugs files</span>
        </div>
        <div className="padding5px docxCheckbox">
          <Checkbox
            value={pathologiesCheckbox}
            onClick={() => setPathologiesCheckbox(!pathologiesCheckbox)}
          />
          <span>Generate pathologies files</span>
        </div>
        {dbKeyList.map((keyObj) => (
          <Stack mb={5} direction="row" alignItems="left">
            <Checkbox onChange={(e) => handleCheckDbValue(keyObj.id)} />
            <SearchInput
              width={240}
              disabled
              placehoder={keyObj.label}
              onChange={(e) => handleChangeDbKey(keyObj.id, e.target.value)}
            />
            <span style={{ width: '5px' }} />
            {keyObj.placeholder2 && keyObj.placeholder2.length ? (
              <SearchInput
                placehoder={keyObj.placeholder2}
                onChange={(e) => handleChangeDbValue2(keyObj.id, e.target.value)}
              />
            ) : null}
            <span style={{ height: '10px' }} />
            <SearchInput
              width={100}
              placehoder={keyObj.placeholder}
              onChange={(e) => handleChangeDbValue(keyObj.id, e.target.value)}
            />
            <div />
            <div />
          </Stack>
        ))}
        {/*
        <Button
          onClick={() => addFilter()}
          style={{ margin: '0px', width: '150px', height: '50px', marginLeft: '50px' }}
          variant="contained"
          to="#"
          disabled={false}
          startIcon={<Icon icon={plusIcon} />}
        >
          New Filter
        </Button>
        */}
        <Button
          onClick={() => submitProcess()}
          style={{ margin: '0px', width: '150px', height: '50px', marginLeft: '50px' }}
          variant="contained"
          to="#"
          disabled={loadZipStatus}
          startIcon={<Icon icon={cloudUploadIcon} />}
        >
          Submit{loadZipStatus ? 'ted' : null}
        </Button>
        {loadZipStatus ? <Loader /> : null}
        <Stack>
          {zipsFromServer.length ? (
            <div>
              <p className="fontSize14">ZIP Files results:</p>
              <div className="scrollmenuContainer">
                <div className="scrollmenu">
                  {zipsFromServer.map((zip, index) => (
                    <div
                      className={cx({
                        // eslint-disable-next-line
                      'fileItem': true
                      })}
                    >
                      <div className="fileItemRemoveContainer">
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <span
                          onClick={() => {
                            removeZip(zip.id);
                            Toast.info(`.zip ${zip.id} removed`);
                          }}
                          className="fileItemRemove"
                        >
                          {getIcon(closeCircleIcon)}
                        </span>
                      </div>
                      <div className="zipItemContainer" style={{ backgroundColor: zip.bg }}>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <div className="pointer">
                          <p className="fileItemName">
                            <strong>
                              files-result.zip
                              {zipsFromServer.length > 1 ? <span> ({zip.id})</span> : null}
                            </strong>
                            <hr />
                          </p>
                          <p className="fileItemName">{bytesToSize(zip.data.size)}</p>
                          {zip.usedFiles && zip.usedFiles.length ? (
                            <p className="fontSize12">Used Files: </p>
                          ) : null}
                          {zip.usedFiles && zip.usedFiles.length
                            ? zip.usedFiles.map((z) => (
                                <p className="fileIncludedInZip">
                                  {z.name}
                                  {/*
                                    {z.context.type[0]}{' '}
                                  {z.context.type === FILE_CONTEXT_TYPE_COMPARE ? (
                                    <span> ➝ {z.context.compare[0]}</span>
                                  ) : null}
                                    */}
                                </p>
                              ))
                            : null}
                          <p className="fileIncludedInZip">{zip.time}</p>
                          <p className="fileIncludedInZip">API Time: {zip.timeExec}</p>
                        </div>
                        <div className="fileSelect">
                          <Button
                            onClick={() => {
                              saveFile(zip.data, zip.id);
                            }}
                            variant="contained"
                            to="#"
                            startIcon={<Icon icon={downloadIcon} />}
                          >
                            Download
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="separator" />
                  <h3 className="mainFAQs">FAQs</h3>
                  <p>How to unzip files on:</p>
                  <p>
                    <a
                      href="https://linuxize.com/post/how-to-unzip-files-in-linux/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Linux >>
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://www.ezyzip.com/how-to-unzip-files-mac.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      MacOS >>
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://www.businessinsider.com/how-to-unzip-files-windows-10"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Windows >>
                    </a>
                  </p>
                </Grid>
              </Grid>
            </div>
          ) : null}
        </Stack>
        {/*
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Filters />
          </Grid>
        </Grid>
        */}

        {/*
        <Grid container spacing={3}>
          <Grid item xs={8}>
          <FileStats />
            <AppOrderTimeline />
          </Grid>
          <Grid item xs={4}>
            <AppTasks />
          </Grid>
          <Grid item xs={12}>
            <DataTable />
          </Grid>
        </Grid>
        */}
      </Container>
    </Page>
  );
}
