import BigNumber from 'bignumber.js';
import validUrl from 'valid-url';
/**
 * 地址截取
 * @param {*} address
 */
export function addressSlice(address, sliceLength = 10) {
  if (address) {
    return `${address.slice(0, sliceLength)}...${address.slice(-sliceLength)}`;
  }
  return address;
}

/**
 * 精度换算
 * @param {*} amount
 * @param {*} decimal
 */
export function amountDecimals(amount, decimal = 0) {
  const realBalance = new BigNumber(amount).dividedBy(new BigNumber(10).pow(decimal)).toString();
  return realBalance;
}

/**
 * 展示金额转换。默认4位小数
 * @param {*} number
 * @param {*} fixed
 */
export function getDisplayAmount(number, fixed = 6) {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(parseFloat(number)) || number === 0) {
    return '0.00';
  }
  const showAmount = new BigNumber(number).toFixed(fixed, 1).toString();
  return toNonExponential(showAmount);
}

export function getAmountDisplay(amount, decimal = 0, fixed = 6) {
  return getDisplayAmount(amountDecimals(amount, decimal), fixed);
}

export const decimalCount = (num) => {
  // Convert to String
  const numStr = String(num);
  // String Contains Decimal
  if (numStr.includes('.')) {
    return numStr.split('.')[1].length;
  }
  // String Does Not Contain Decimal
  return 0;
};

/**
 * 去掉字符串前后空格
 * @param {*} str
 */
export function trimSpace(str) {
  let res = str.replace(/(^\s*)|(\s*$)/g, '');
  res = res.replace(/[\r\n]/g, '');
  return res;
}

/**
 * 校验地址是否有效
 * @param {*} url
 */
export function urlValid(url) {
  if (validUrl.isWebUri(url)) {
    return true;
  }
  return false;
}

/**
 * 判断是不是数字
 * @param n
 * @param includeE 是否认为科学计数法也算作数字 默认不算
 */
export function isNumber(n, includeE = false) {
  const isNum = !!String(n).match(/^\d+\.?(?:\.\d+)?$/);
  if (!isNum && includeE) {
    return !!String(n).match(/^\d+e(-)?\d+$/);
  }
  return isNum;
}

/**
 * 校验是否是大于0 的整数
 * @param {*} n
 * @param {*} includeE
 * @returns
 */
export function isTrueNumber(n) {
  const isNum = !!String(n).match(/^([1-9][0-9]*)$/);
  return isNum;
}

/**
 * 校验用户名长度 默认16位
 * @param {*} name
 * @param {*} defaultLength
 */
export function nameLengthCheck(name, defaultLength = 16) {
  let realLength = 0;
  const len = name.length;
  let charCode = -1;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    charCode = name.charCodeAt(i);
    if (charCode >= 0 && charCode <= 128) {
      realLength += 1;
    } else {
      realLength += 2;
    }
  }
  if (realLength > defaultLength) {
    return false;
  }
  return true;
}

/**
 * 复制文本
 */
export function copyText(text) {
  return navigator.clipboard.writeText(text).catch((error) => {
    alert(`Copy failed! ${error}`);
  });
}

export function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value);
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function positiveNumber(n) {
  let pNum = n;
  if (n < 0) {
    pNum *= -1;
  }
  return pNum;
}

export function dictIsEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function shuffle(array) {
  let currentIndex = array.length;
  let randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    // eslint-disable-next-line no-plusplus
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

export function containsObject(obj, list) {
  let i;
  // eslint-disable-next-line no-plusplus
  for (i = 0; i < list.length; i++) {
    if (list[i] === obj) {
      return true;
    }
  }

  return false;
}

function toNonExponential(numStr) {
  numStr = numStr.toString();
  if (numStr.indexOf('+') !== -1) {
    numStr = numStr.replace('+', '');
  }
  if (numStr.indexOf('E') !== -1 || numStr.indexOf('e') !== -1) {
    let resValue = '';
    let power = '';
    let result = null;
    let dotIndex = 0;
    let resArr = [];
    let sym = '';
    let numStr = numStr.toString();
    if (numStr[0] === '-') {
      numStr = numStr.substr(1);
      sym = '-';
    }
    if (numStr.indexOf('E') !== -1 || numStr.indexOf('e') !== -1) {
      const regExp = new RegExp('^(((\\d+.?\\d+)|(\\d+))[Ee]{1}((-(\\d+))|(\\d+)))$', 'ig');
      result = regExp.exec(numStr);
      if (result != null) {
        // eslint-disable-next-line prefer-destructuring
        resValue = result[2];
        // eslint-disable-next-line prefer-destructuring
        power = result[5];
        result = null;
      }
      if (!resValue && !power) {
        return false;
      }
      dotIndex = resValue.indexOf('.') === -1 ? 0 : resValue.indexOf('.');
      resValue = resValue.replace('.', '');
      resArr = resValue.split('');
      if (Number(power) >= 0) {
        const subres = resValue.substr(dotIndex);
        power = Number(power);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i <= power - subres.length; i++) {
          resArr.push('0');
        }
        if (power - subres.length < 0) {
          resArr.splice(dotIndex + power, 0, '.');
        }
      } else {
        power = power.replace('-', '');
        power = Number(power);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < power - dotIndex; i++) {
          resArr.unshift('0');
        }
        const n = power - dotIndex >= 0 ? 1 : -(power - dotIndex);
        resArr.splice(n, 0, '.');
      }
    }
    resValue = resArr.join('');

    return sym + resValue;
  }
  return numStr;
}

export function getBase64(file) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    console.log(reader.result);
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
}
export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  // eslint-disable-next-line eqeqeq
  if (bytes == 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  // eslint-disable-next-line no-restricted-properties
  return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
}

export function convertMiliseconds(miliseconds, format) {
  let days;
  let hours;
  let minutes;
  let seconds;
  let totalHours;
  let totalMinutes;
  let totalSeconds;

  // eslint-disable-next-line prefer-const
  totalSeconds = parseInt(Math.floor(miliseconds / 1000), 10);
  // eslint-disable-next-line prefer-const
  totalMinutes = parseInt(Math.floor(totalSeconds / 60), 10);
  // eslint-disable-next-line prefer-const
  totalHours = parseInt(Math.floor(totalMinutes / 60), 10);
  // eslint-disable-next-line prefer-const
  days = parseInt(Math.floor(totalHours / 24), 10);

  // eslint-disable-next-line prefer-const
  seconds = parseInt(totalSeconds % 60, 10);
  // eslint-disable-next-line prefer-const
  minutes = parseInt(totalMinutes % 60, 10);
  // eslint-disable-next-line prefer-const
  hours = parseInt(totalHours % 24, 10);

  switch (format) {
    case 's':
      return totalSeconds;
    case 'm':
      return totalMinutes;
    case 'h':
      return totalHours;
    case 'd':
      return days;
    default:
      return { d: days, h: hours, m: minutes, s: seconds };
  }
}
export function testRegex(regex, data) {
  return data.match(regex);
}
