import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import myomicsLogo from '../../assets/img/myomics-logo.png';
import './index.css';
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return <Box className="logo" component="img" src={myomicsLogo} sx={{ height: 40, ...sx }} />;
}
