import PropTypes from 'prop-types';
// material
import { MenuItem, TextField } from '@mui/material';
import './index.scss';
import { useState } from 'react';
// ----------------------------------------------------------------------

SelectInput.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array,
  onSelect: PropTypes.func
};

export default function SelectInput({ options, onSelect }) {
  const [selectedValue, setSelectedValue] = useState(options[0].value);
  return (
    <TextField
      select
      size="small"
      value={selectedValue}
      onChange={onSelect}
      className="searchInput"
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          onClick={() => {
            setSelectedValue(option.value);
          }}
        >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
